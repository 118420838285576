export default {
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricula do camião"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleção de combustivel"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleciona um planning"])},
  "expedition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expedição"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno"])},
  "return?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vem com retorno?"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conheço e aceito os termos"])},
  "securityProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["protocolo de segurança"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidade"])},
  "andThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
  "gasoil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesel"])},
  "gasoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasolina"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gás"])},
  "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elétrico"])},
  "trailerRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricula do trailer"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "editDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar condutor"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
  "nif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIF"])},
  "cmrNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de CMR"])},
  "subCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportadora Subcontratada"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepção"])},
  "carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportadora"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem"])},
  "trailerEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Se não houver, deixar em branco)"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar"])},
  "planningsOfToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planejamento de hoje"])},
  "planningsOfTomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planejamento de amanhã"])},
  "oneDayMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amanhã"])},
  "oneDayLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontem"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])}
}