export default {
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation du tracteur"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection du carburant"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prenom"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre livraison"])},
  "expedition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "return?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce qu'il y a retour ?"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je connais et j'accepte les conditions"])},
  "securityProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocole de sécurité"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
  "andThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
  "gasoil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesel"])},
  "gasoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essence"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaz"])},
  "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Électrique"])},
  "trailerRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaque d'immatriculation de remorque"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "editDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le conducteur"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "nif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOM ET JOUR DE NAISSANCE (ex:ROGER12)"])},
  "cmrNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro CMR"])},
  "subCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporteur sous-traité"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception"])},
  "carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporteur"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
  "trailerEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remorque Vide"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement"])},
  "planningsOfToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning d'aujourd'hui"])},
  "planningsOfTomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning de demain"])},
  "oneDayMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demain"])},
  "oneDayLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])}
}